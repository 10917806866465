import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';
import slick from 'slick-carousel';

$(document).foundation();

$(document).ready(function() {
	

	if ( $( '#MemberModal' ).length ) {
 
        //$('#MemberModal').foundation('open');
 
    }

    if ( $( '#covid' ).length ) {

        console.log('covid');
 
        $('#covid').foundation('open');
 
    }

    if ( $('.recaptcha_error').length ) {

        console.log('running');

        var invalid_fields = $(this).find('.recaptcha_error');

        $('html, body').animate({
            scrollTop: $(invalid_fields[0]).offset().top - 200
        }, 1000, function() {
            $(invalid_fields[0]).focus();
        });
 
    }

    if ( $('.contact-success').length ) {
        console.log('running');

        var invalid_fields = $(this).find('.contact-success');

        $('html, body').animate({
            scrollTop: $(invalid_fields[0]).offset().top - 200
        }, 1000, function() {
            $(invalid_fields[0]).focus();
        });
 
    }
    

    $('form').on('forminvalid.zf.abide', function () {

        var invalid_fields = $(this).find('[data-invalid]');

        $('html, body').animate({
            scrollTop: $(invalid_fields[0]).offset().top - 200
        }, 1000, function() {
            $(invalid_fields[0]).focus();
        });

    });


    $('.slick-slider').not('.slick-initialized').slick({
        lazyLoad: 'ondemand',
        infinite: true,
        autoplay: 1,
        autoplaySpeed: 2000,
        arrows: true,
        dots: true,
        responsive: [
            { breakpoint: 4000, settings: { slidesToShow: 6, slidesToScroll: 1 } }, // 4000px and below
            { breakpoint: 3000, settings: { slidesToShow: 6, slidesToScroll: 1 } }, // 3000px and below
            { breakpoint: 2000, settings: { slidesToShow: 6, slidesToScroll: 1 } }, // 2000px and below
            { breakpoint: 1024, settings: { slidesToShow: 6, slidesToScroll: 1 } }, // etc... 
            { breakpoint: 880, settings: { slidesToShow: 6, slidesToScroll: 1 } },
            { breakpoint: 640, settings: { slidesToShow: 2, slidesToScroll: 1 } },
            { breakpoint: 500, settings: { slidesToShow: 1, slidesToScroll: 1 } }
        ]
    });

	//var stHeight = $('.slick-track').height();
	//$('.slick-slide').css('height',stHeight + 'px' );

    $('.hero-slider').not('.slick-initialized').slick({
        lazyLoad: 'ondemand',
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
        dots: true
    });



    // MXCalendar stuff

    /*
     * mxCalendar AJAX navigation
     * by http://charlesmx.com
     * for ModX Revo
     */
    $(function() { 
        var mxcCalPreContent = '';
        var mxcCalNexContent = '';
        var todayContent = '';
        var mxcCalPrev;
        var mxcCalNext;
        var urlParams = {};
        var mxcHistory = [];
        var ajaxObj = '',
            nidx = '',
            nidxp = '',
            ajaxObjP ='',
            modalActive = '';
        (function () {
            var e,
                a = /\+/g,  // Regex for replacing addition symbol with a space
                r = /([^&=]+)=?([^&]*)/g,
                d = function (s) { return decodeURIComponent(s.replace(a, " ")); },
                q = window.location.search.substring(1);

            while (e = r.exec(q))
               urlParams[d(e[1])] = d(e[2]);
        })();
    
    function ajaxmxc(){
        mxcCalNext = document.getElementById("mxcnextlnk");
        mxcCalPrev = document.getElementById("mxcprevlnk");
        $("#mxcnextlnk, #mxcprevlnk").addClass("loading");
        if(mxcCalNext) {
            ajaxObj = '';
            nidx = mxcCalNext.href.indexOf("dt=");
            if(nidx != -1){
                ajaxObj = mxcCalNext.href.substring((nidx + 3),(nidx + 10));
                if(!mxcHistory[ajaxObj]){
                    $.get(mxcCalNext.href+"&imajax=1 #calbody", {},
                       function(data){
                         mxcCalNexContent = data;
                         $("#mxcnextlnk").removeClass("loading");
                       });
                } else {
                    mxcCalNexContent = mxcHistory[ajaxObj];
                    $("#mxcnextlnk").removeClass("loading");
                }     
            }
        }
        if(mxcCalPrev) {
            ajaxObjP = '';
            nidxp = mxcCalPrev.href.indexOf("dt=");
            if(nidxp != -1){
                ajaxObjP = mxcCalPrev.href.substring((nidxp + 3),(nidxp + 10));
                if(!mxcHistory[ajaxObjP]){
                    $.get(mxcCalPrev.href+"&imajax=1 #calbody", {},
                       function(data){
                         mxcCalPreContent = data;
                         mxcHistory[ajaxObjP] = data;
                         $("#mxcprevlnk").removeClass("loading");
                       });
                } else {
                    mxcCalPreContent = mxcHistory[ajaxObjP];
                    $("#mxcprevlnk").removeClass("loading");
                }     
            }
        }
        if(modalActive){
            Shadowbox.teardown('.mxcmodal');
            Shadowbox.clearCache();
            Shadowbox.setup(".mxcmodal", sbOptions);
        }
        
        mxcBindEvents();
    }
    function addHistory(url){
        var stateObj = {};
        if(url)
        history.pushState(stateObj, "Calendar", url);
    }
    function mxcBindEvents(){
        $('#mxcnextlnk').on('click', function(event) { 
        event.preventDefault();
        if(!$("#mxcnextlnk, #mxcprevlnk").hasClass('loading')){
            $("#calbody").html(mxcCalNexContent);
            //addHistory(mxcCalNext);
            ajaxmxc();
        }
        });
        $('#mxcprevlnk').on('click', function(event) { 
            event.preventDefault();
            if(!$("#mxcnextlnk, #mxcprevlnk").hasClass('loading')){
                $("#calbody").html(mxcCalPreContent);
                //addHistory(mxcCalPrev);
                ajaxmxc();
            }
        });
        $('#mxctodaylnk').on('click', function(event) { 
            event.preventDefault();
            if(todayContent != ''){
                $("#calbody").html(todayContent);
                ajaxmxc();
            } else {
                $.get(this.href+"&imajax=1", {},
                function(data){
                    todayContent = data;
                    $("#calbody").html(todayContent);
                    ajaxmxc();
                });
            }
        });
    }
    
    //-- Get today's content
    if(document.getElementById("mxctodaylnk") != null && todayContent == '')
    $.get(document.getElementById("mxctodaylnk").href+"&imajax=1", {},
       function(data){
         todayContent = data;
       });
    ajaxmxc();
    
});





    

}); // document ready 
